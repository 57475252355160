import axios from 'axios'
import qs from 'qs';

export default {
    // stores
    stores(payload) {
        return axios.get('/stores', payload)
    },
    storesDelete(payload) {
        return axios.delete('/stores/' + payload.id, payload)
    },
    storesUpdate(payload) {
        return axios.post('/stores/' + payload.id, qs.stringify(payload))
    },
    storesCreate(payload) {
        return axios.post('/stores', qs.stringify(payload))
    },
    storesGet(payload) {
        return axios.get('/stores/' + payload.id, payload)
    },
    storesLeftovers(payload) {
        return axios.get('/stores/' + payload.id + '/leftovers', payload)
    },
    // supplies
    supplies(payload) {
        return axios.get('/supplies', payload)
    },
    suppliesUpdate(payload) {
        return axios.post('/supplies/' + payload.id, qs.stringify(payload))
    },
    suppliesGet(payload) {
        return axios.get('/supplies/' + payload.id, payload)
    },
    // supplies plan
    suppliesPlan(payload) {
        return axios.get('/supplies-plan', payload)
    },
    suppliesPlanDelete(payload) {
        return axios.delete('/supplies-plan/' + payload.id, payload)
    },
    suppliesPlanUpdate(payload) {
        return axios.post('/supplies-plan/' + payload.id, qs.stringify(payload))
    },
    suppliesPlanCreate(payload) {
        return axios.post('/supplies-plan', qs.stringify(payload))
    },
    suppliesPlanGet(payload) {
        return axios.get('/supplies-plan/' + payload.id, payload)
    },
    suppliesPlanPurchase(payload) {
        return axios.post('/supplies-plan/' + payload.id + '/purchase', qs.stringify(payload))
    },
    suppliesPlanConfirm(payload) {
        return axios.post('/supplies-plan/' + payload.get("id") + '/confirm', payload)
    },
    // write-offs
    writeOffs(payload) {
        return axios.get('/write-offs', payload)
    },
    writeOffsCreate(payload) {
        return axios.post('/write-offs', qs.stringify(payload))
    },
    writeOffsUpdate(payload) {
        return axios.post('/write-offs/' + payload.id, qs.stringify(payload))
    },
    writeOffsGet(payload) {
        return axios.get('/write-offs/' + payload.id, payload)
    },
}