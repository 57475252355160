import * as types from '@/store/mutation-types'
import router from '@/router'
import api from '@/services/api/auth'
import { buildSuccess, handleError } from '@/utils/utils.js'

const getters = {
    admin: (state) => state.admin,
    role: (state) => state.role,
    token: (state) => state.token,
    isTokenSet: (state) => state.isTokenSet
};

const actions = {
    authLogin({ commit }, payload) {
        return new Promise((resolve, reject) => {
            commit(types.SHOW_LOADING, true);
            api
                .authLogin(payload)
                .then((response) => {
                    if (response.status === 200) {
                        window.localStorage.setItem('admin', JSON.stringify(response.data.admin));
                        window.localStorage.setItem('role', JSON.stringify(response.data.role));
                        window.localStorage.setItem('token', response.data.token);
                        commit(types.SAVE_ADMIN, response.data.admin);
                        commit(types.SAVE_ROLE, response.data.role);
                        commit(types.SAVE_TOKEN, response.data.token);
                        buildSuccess(
                            null,
                            commit,
                            resolve,
                            router.push({
                                name: 'DeliveryOrders'
                            })
                        )
                    }
                })
                .catch((error) => {
                    handleError(error, commit, reject)
                })
        })
    },
    autoLogin({ commit }) {
        commit(types.SAVE_ADMIN, JSON.parse(localStorage.getItem('admin')));
        commit(types.SAVE_ROLE, JSON.parse(localStorage.getItem('role')));
        commit(types.SAVE_TOKEN, localStorage.getItem('token'));
    },
    authLogout({ commit }) {
        window.localStorage.removeItem('token');
        window.localStorage.removeItem('admin');
        window.localStorage.removeItem('role');
        commit(types.LOGOUT);
        router.push({
            name: 'Login'
        })
    }
};

const mutations = {
    [types.SAVE_TOKEN](state, token) {
        state.token = token;
        state.isTokenSet = true
    },
    [types.SAVE_ADMIN](state, admin) {
        state.admin = admin
    },
    [types.SAVE_ROLE](state, role) {
        state.role = role
    },
    [types.LOGOUT](state) {
        state.admin = null;
        state.token = null;
        state.role = null;
        state.isTokenSet = false
    }
};

const state = {
    admin: null,
    role: null,
    token: !!localStorage.getItem('token') || null,
    isTokenSet: !!localStorage.getItem('token') && localStorage.getItem('token') !== "undefined"
};

export default {
    state,
    getters,
    actions,
    mutations
}
