import axios from 'axios'
import qs from 'qs';

export default {
    categories(payload) {
        return axios.get('/metro/categories' + (payload.id !== null ? "/" + payload.id : ""), payload)
    },
    categoriesProducts(payload) {
        return axios.get('/metro/categories/' + payload.id + "/products", payload)
    },
    categoriesSettings(payload) {
        return axios.post('/metro/categories/' + payload.id + "/settings", qs.stringify(payload))
    }
}
