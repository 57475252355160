import * as types from '@/store/mutation-types'
import router from '@/router'
import api from '@/services/api/admins'
import { buildSuccess, handleError } from '@/utils/utils'

const getters = {
    admins: (state) => state.admins,
    adminGet: (state) => state.adminGet
};

const actions = {
    adminsIndex({ commit }, payload) {
        return new Promise((resolve, reject) => {
            commit(types.SHOW_LOADING, true);
            api
                .admins(payload)
                .then((response) => {
                    if (response.status === 200) {
                        buildSuccess(
                            null,
                            commit,
                            resolve
                        );
                        commit(types.FILL_ADMINS_INDEX, response.data);
                    }
                })
                .catch((error) => {
                    handleError(error, commit, reject)
                })
        })
    },
    adminsGet({ commit }, payload) {
        return new Promise((resolve, reject) => {
            commit(types.SHOW_LOADING, true);
            api
                .adminsGet(payload)
                .then((response) => {
                    if (response.status === 200) {
                        buildSuccess(
                            null,
                            commit,
                            resolve
                        );
                        commit(types.FILL_ADMINS_ADMIN, response.data);
                    }
                })
                .catch((error) => {
                    handleError(error, commit, reject)
                })
        })
    },
    adminsUpdate({ commit }, payload) {
        return new Promise((resolve, reject) => {
            commit(types.SHOW_LOADING, true);
            api
                .adminsUpdate(payload)
                .then((response) => {
                    if (response.status === 200) {
                        buildSuccess(
                            null,
                            commit,
                            resolve,
                            router.go()
                        );
                    }
                })
                .catch((error) => {
                    handleError(error, commit, reject)
                })
        })
    }
};

const mutations = {
    [types.FILL_ADMINS_INDEX](state, data) {
        state.admins = data;
    },
    [types.FILL_ADMINS_ADMIN](state, data) {
        state.adminGet = data;
    }
};

const state = {
    admins: null,
    adminGet: null
};

export default {
    state,
    getters,
    actions,
    mutations
}
