import * as types from '@/store/mutation-types'
import router from '@/router'
import api from '@/services/api/delivery'
import { buildSuccess, handleError } from '@/utils/utils'

const getters = {
    orders: (state) => state.orders,
    order: (state) => state.order,
    statusesHistory: (state) => state.statusesHistory,
    productsHistory: (state) => state.productsHistory,
    orderErrorMessage: (state) => state.orderErrorMessage,
};

const actions = {
    deliveryOrders({ commit }, payload) {
        return new Promise((resolve, reject) => {
            commit(types.SHOW_LOADING, true);
            api
                .orders(payload)
                .then((response) => {
                    if (response.status === 200) {
                        buildSuccess(
                            null,
                            commit,
                            resolve
                        );
                        commit(types.FILL_DELIVERY_ORDERS, response.data);
                    }
                })
                .catch((error) => {
                    handleError(error, commit, reject)
                })
        })
    },
    deliveryOrdersGet({ commit }, payload) {
        return new Promise((resolve, reject) => {
            commit(types.FILL_DELIVERY_ORDER, null);
            commit(types.SHOW_LOADING, true);
            api
                .ordersGet(payload)
                .then((response) => {
                    if (response.status === 200) {
                        buildSuccess(
                            null,
                            commit,
                            resolve
                        );
                        commit(types.FILL_DELIVERY_ORDER, response.data);
                    }
                })
                .catch((error) => {
                    handleError(error, commit, reject)
                })
        })
    },
    deliveryOrdersAddress({ commit }, payload) {
        return new Promise((resolve, reject) => {
            commit(types.SHOW_LOADING, true);
            api
                .ordersAddress(payload)
                .then((response) => {
                    if (response.status === 200) {
                        buildSuccess(
                            null,
                            commit,
                            resolve,
                            router.go()
                        );
                    }
                })
                .catch((error) => {
                    handleError(error, commit, reject)
                })
        })
    },
    deliveryOrdersProducts({ commit }, payload) {
        return new Promise((resolve, reject) => {
            commit(types.SHOW_LOADING, true);
            api
                .ordersProducts(payload)
                .then((response) => {
                    if (response.status === 200) {
                        buildSuccess(
                            null,
                            commit,
                            resolve,
                            router.push({
                                name: 'DeliveryOrdersUpdate',
                                query: {
                                    t: Date.now()
                                },
                                params: {
                                    id: payload.id
                                }
                            })
                        );
                    }
                })
                .catch((error) => {
                    commit(types.FILL_DELIVERY_ORDER_MESSAGE, error.response.data.message);
                    handleError(error, commit, reject)
                })
        })
    },
    deliveryOrdersStatus({ commit }, payload) {
        return new Promise((resolve, reject) => {
            commit(types.SHOW_LOADING, true);
            api
                .ordersStatus(payload)
                .then((response) => {
                    if (response.status === 200) {
                        buildSuccess(
                            null,
                            commit,
                            resolve,
                            router.go()
                        );
                    }
                })
                .catch((error) => {
                    handleError(error, commit, reject)
                })
        })
    },
    deliveryOrdersPaymentMethod({ commit }, payload) {
        return new Promise((resolve, reject) => {
            commit(types.SHOW_LOADING, true);
            api
                .ordersPaymentMethod(payload)
                .then((response) => {
                    if (response.status === 200) {
                        buildSuccess(
                            null,
                            commit,
                            resolve,
                            router.go()
                        );
                    }
                })
                .catch((error) => {
                    handleError(error, commit, reject)
                })
        })
    },
    deliveryOrdersStatusesHistory({ commit }, payload) {
        return new Promise((resolve, reject) => {
            commit(types.SHOW_LOADING, true);
            api
                .ordersStatusesHistory(payload)
                .then((response) => {
                    if (response.status === 200) {
                        buildSuccess(
                            null,
                            commit,
                            resolve
                        );
                        commit(types.FILL_DELIVERY_ORDER_STATUSES_HISTORY, response.data);
                    }
                })
                .catch((error) => {
                    handleError(error, commit, reject)
                })
        })
    },
    deliveryOrdersProductsHistory({ commit }, payload) {
        return new Promise((resolve, reject) => {
            commit(types.SHOW_LOADING, true);
            api
                .ordersProductsHistory(payload)
                .then((response) => {
                    if (response.status === 200) {
                        buildSuccess(
                            null,
                            commit,
                            resolve
                        );
                        commit(types.FILL_DELIVERY_ORDER_PRODUCTS_HISTORY, response.data);
                    }
                })
                .catch((error) => {
                    handleError(error, commit, reject)
                })
        })
    },
    deliveryOrdersMessageClear({ commit }, payload) {
        commit(types.FILL_DELIVERY_ORDER_MESSAGE, null);
    }
};

const mutations = {
    [types.FILL_DELIVERY_ORDERS](state, data) {
        state.orders = data
    },
    [types.FILL_DELIVERY_ORDER](state, data) {
        state.order = data
    },
    [types.FILL_DELIVERY_ORDER_STATUSES_HISTORY](state, data) {
        state.statusesHistory = data
    },
    [types.FILL_DELIVERY_ORDER_PRODUCTS_HISTORY](state, data) {
        state.productsHistory = data
    },
    [types.FILL_DELIVERY_ORDER_MESSAGE](state, data) {
        state.orderErrorMessage = data
    }
};

const state = {
    orders: null,
    order: null,
    statusesHistory: [],
    productsHistory: [],
    orderErrorMessage: null
};

export default {
    state,
    getters,
    actions,
    mutations
}
