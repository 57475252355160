// error
export const SHOW_ERROR = 'SHOW_ERROR';
export const ERROR = 'ERROR';
// success
export const SHOW_SUCCESS = 'SHOW_SUCCESS';
export const SUCCESS = 'SUCCESS';
// loading
export const SHOW_LOADING = 'SHOW_LOADING';
// auth
export const SAVE_TOKEN = 'SAVE_TOKEN';
export const SAVE_ADMIN = 'SAVE_ADMIN';
export const SAVE_ROLE = 'SAVE_ROLE';
export const LOGOUT = 'LOGOUT';
// catalog
export const FILL_CATALOG_CATEGORIES = 'FILL_CATALOG_CATEGORIES';
export const FILL_CATALOG_CATEGORY = 'FILL_CATALOG_CATEGORY';
export const FILL_CATALOG_PRODUCTS = 'FILL_CATALOG_PRODUCTS';
export const FILL_CATALOG_PRODUCT = 'FILL_CATALOG_PRODUCT';
export const FILL_CATALOG_CATEGORIES_TREE = 'FILL_CATALOG_CATEGORIES_TREE';
export const FILL_CATALOG_MANUFACTURERS = 'FILL_CATALOG_MANUFACTURERS';
export const FILL_CATALOG_MANUFACTURER = 'FILL_CATALOG_MANUFACTURER';
export const FILL_CATALOG_PROMOTIONS = 'FILL_CATALOG_PROMOTIONS';
export const FILL_CATALOG_PROMOTION = 'FILL_CATALOG_PROMOTION';
export const FILL_CATALOG_PROMOTIONS_PRODUCTS = 'FILL_CATALOG_PROMOTIONS_PRODUCTS';
export const FILL_CATALOG_WEEKLYDEALS = 'FILL_CATALOG_WEEKLYDEALS';
// delivery orders
export const FILL_DELIVERY_ORDERS = 'FILL_DELIVERY_ORDERS';
export const FILL_DELIVERY_ORDER = 'FILL_DELIVERY_ORDER';
export const FILL_DELIVERY_ORDER_STATUSES_HISTORY = 'FILL_DELIVERY_ORDER_STATUSES_HISTORY';
export const FILL_DELIVERY_ORDER_PRODUCTS_HISTORY = 'FILL_DELIVERY_ORDER_PRODUCTS_HISTORY';
export const FILL_DELIVERY_ORDER_MESSAGE = 'FILL_DELIVERY_ORDER_MESSAGE';
// delivery users
export const FILL_DELIVERY_USERS = 'FILL_DELIVERY_USERS';
export const FILL_DELIVERY_USER = 'FILL_DELIVERY_USER';
// metro
export const FILL_METRO_CATEGORIES = 'FILL_METRO_CATEGORIES';
export const FILL_METRO_PRODUCTS = 'FILL_METRO_PRODUCTS';
// admins
export const FILL_ADMINS_INDEX = 'FILL_ADMINS_INDEX';
export const FILL_ADMINS_ADMIN = 'FILL_ADMINS_ADMIN';
// store
export const FILL_STORE_STORES = 'FILL_STORE_STORES';
export const FILL_STORE_STORE = 'FILL_STORE_STORE';
export const FILL_STORE_LEFTOVERS = 'FILL_STORE_LEFTOVERS';
// supplies
export const FILL_STORE_SUPPLIES = 'FILL_STORE_SUPPLIES';
export const FILL_STORE_SUPPLY = 'FILL_STORE_SUPPLY';
// supplies plan
export const FILL_STORE_SUPPLIES_PLAN = 'FILL_STORE_SUPPLIES_PLAN';
export const FILL_STORE_SUPPLY_PLAN = 'FILL_STORE_SUPPLY_PLAN';
// write-offs
export const FILL_STORE_WRITE_OFFS = 'FILL_STORE_WRITE_OFFS';
export const FILL_STORE_WRITE_OFF = 'FILL_STORE_WRITE_OFF';