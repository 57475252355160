import * as types from '@/store/mutation-types'
import api from '@/services/api/store'
import router from '@/router'
import { buildSuccess, handleError } from '@/utils/utils'

const getters = {
    supplies: (state) => state.supplies,
    supply: (state) => state.supply,
};

const actions = {
    storeSupplies({ commit }, payload) {
        return new Promise((resolve, reject) => {
            commit(types.SHOW_LOADING, true);
            commit(types.FILL_STORE_SUPPLIES, []);
            api
                .supplies(payload)
                .then((response) => {
                    if (response.status === 200) {
                        buildSuccess(
                            null,
                            commit,
                            resolve
                        );
                        commit(types.FILL_STORE_SUPPLIES, response.data);
                    }
                })
                .catch((error) => {
                    handleError(error, commit, reject)
                })
        })
    },
    storeSuppliesGet({ commit }, payload) {
        return new Promise((resolve, reject) => {
            commit(types.SHOW_LOADING, true);
            api
                .suppliesGet(payload)
                .then((response) => {
                    if (response.status === 200) {
                        buildSuccess(
                            null,
                            commit,
                            resolve
                        );
                        commit(types.FILL_STORE_SUPPLY, response.data);
                    }
                })
                .catch((error) => {
                    handleError(error, commit, reject)
                })
        })
    },
    storeSuppliesUpdate({ commit }, payload) {
        return new Promise((resolve, reject) => {
            commit(types.SHOW_LOADING, true);
            api
                .suppliesUpdate(payload)
                .then((response) => {
                    if (response.status === 200) {
                        buildSuccess(
                            null,
                            commit,
                            resolve,
                            router.go()
                        );
                    }
                })
                .catch((error) => {
                    handleError(error, commit, reject)
                })
        })
    },
};

const mutations = {
    [types.FILL_STORE_SUPPLIES](state, data) {
        state.supplies = data
    },
    [types.FILL_STORE_SUPPLY](state, data) {
        state.supply = data
    }
};

const state = {
    supplies: [],
    supply: null,
};

export default {
    state,
    getters,
    actions,
    mutations
}
