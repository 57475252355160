import * as types from '@/store/mutation-types'
import router from '@/router'
import api from '@/services/api/catalog'
import { buildSuccess, handleError } from '@/utils/utils'

const getters = {
    product: (state) => state.product
};

const actions = {
    catalogProductGet({ commit }, payload) {
        return new Promise((resolve, reject) => {
            commit(types.FILL_CATALOG_PRODUCT, null);
            commit(types.SHOW_LOADING, true);
            api
                .productGet(payload)
                .then((response) => {
                    if (response.status === 200) {
                        buildSuccess(
                            null,
                            commit,
                            resolve
                        );
                        commit(types.FILL_CATALOG_PRODUCT, response.data);
                    }
                })
                .catch((error) => {
                    handleError(error, commit, reject)
                })
        })
    },
    catalogProductDelete({ commit }, payload) {
        return new Promise((resolve, reject) => {
            commit(types.SHOW_LOADING, true);
            api
                .productDelete(payload)
                .then((response) => {
                    if (response.status === 200) {
                        buildSuccess(
                            null,
                            commit,
                            resolve
                        );
                    }
                })
                .catch((error) => {
                    handleError(error, commit, reject)
                })
        })
    },
    catalogProductUpdate({ commit }, payload) {
        return new Promise((resolve, reject) => {
            commit(types.SHOW_LOADING, true);
            console.log(payload);
            api
                .productUpdate(payload)
                .then((response) => {
                    if (response.status === 200) {
                        buildSuccess(
                            null,
                            commit,
                            resolve,
                            router.go()
                        );
                    }
                })
                .catch((error) => {
                    handleError(error, commit, reject)
                })
        })
    },
    catalogProductCreate({ commit }, payload) {
        return new Promise((resolve, reject) => {
            commit(types.SHOW_LOADING, true);
            api
                .productCreate(payload)
                .then((response) => {
                    if (response.status === 200) {
                        buildSuccess(
                            null,
                            commit,
                            resolve,
                            router.push({
                                name: 'CatalogCategoriesProducts',
                                params: {
                                    id: payload.get("category_id")
                                }
                            })
                        );
                    }
                })
                .catch((error) => {
                    handleError(error, commit, reject)
                })
        })
    },
};

const mutations = {
    [types.FILL_CATALOG_PRODUCT](state, data) {
        state.product = data
    }
};

const state = {
    product: null
};

export default {
    state,
    getters,
    actions,
    mutations
}
