import * as types from '@/store/mutation-types'
import api from '@/services/api/catalog'
import router from '@/router'
import { buildSuccess, handleError } from '@/utils/utils'

const getters = {
    manufacturers: (state) => state.manufacturers,
    manufacturer: (state) => state.manufacturer,
};

const actions = {
    catalogManufacturers({ commit }, payload) {
        return new Promise((resolve, reject) => {
            commit(types.SHOW_LOADING, true);
            api
                .manufacturers(payload)
                .then((response) => {
                    if (response.status === 200) {
                        buildSuccess(
                            null,
                            commit,
                            resolve
                        );
                        commit(types.FILL_CATALOG_MANUFACTURERS, response.data);
                    }
                })
                .catch((error) => {
                    handleError(error, commit, reject)
                })
        })
    },
    catalogManufacturersDelete({ commit }, payload) {
        return new Promise((resolve, reject) => {
            commit(types.SHOW_LOADING, true);
            api
                .manufacturersDelete(payload)
                .then((response) => {
                    if (response.status === 200) {
                        buildSuccess(
                            null,
                            commit,
                            resolve
                        );
                    }
                })
                .catch((error) => {
                    handleError(error, commit, reject)
                })
        })
    },
    catalogManufacturersGet({ commit }, payload) {
        return new Promise((resolve, reject) => {
            commit(types.SHOW_LOADING, true);
            api
                .manufacturersGet(payload)
                .then((response) => {
                    if (response.status === 200) {
                        buildSuccess(
                            null,
                            commit,
                            resolve
                        );
                        commit(types.FILL_CATALOG_MANUFACTURER, response.data);
                    }
                })
                .catch((error) => {
                    handleError(error, commit, reject)
                })
        })
    },
    catalogManufacturersUpdate({ commit }, payload) {
        return new Promise((resolve, reject) => {
            commit(types.SHOW_LOADING, true);
            api
                .manufacturersUpdate(payload)
                .then((response) => {
                    if (response.status === 200) {
                        buildSuccess(
                            null,
                            commit,
                            resolve,
                            router.go()
                        );
                    }
                })
                .catch((error) => {
                    handleError(error, commit, reject)
                })
        })
    },
    catalogManufacturersCreate({ commit }, payload) {
        return new Promise((resolve, reject) => {
            commit(types.SHOW_LOADING, true);
            api
                .manufacturersCreate(payload)
                .then((response) => {
                    if (response.status === 200) {
                        buildSuccess(
                            null,
                            commit,
                            resolve,
                            router.push({
                                name: 'CatalogManufacturers'
                            })
                        );
                    }
                })
                .catch((error) => {
                    handleError(error, commit, reject)
                })
        })
    },
};

const mutations = {
    [types.FILL_CATALOG_MANUFACTURERS](state, data) {
        state.manufacturers = data
    },
    [types.FILL_CATALOG_MANUFACTURER](state, data) {
        state.manufacturer = data
    }
};

const state = {
    manufacturers: null,
    manufacturer: null,
};

export default {
    state,
    getters,
    actions,
    mutations
}
