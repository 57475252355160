export default [
    // stores
    {
        path: '/stores',
        name: 'StoreStores',
        component: () => import('../../views/store/stores/Index.vue')
    },
    {
        path: '/stores/create',
        name: 'StoreStoresCreate',
        component: () => import('../../views/store/stores/Create.vue')
    },
    {
        path: '/stores/:id',
        name: 'StoreStoresUpdate',
        component: () => import('../../views/store/stores/Update.vue')
    },
    {
        path: '/stores/:id/leftovers',
        name: 'StoreStoresLeftovers',
        component: () => import('../../views/store/stores/Leftovers.vue')
    },
    // supplies
    {
        path: '/supplies',
        name: 'StoreSupplies',
        component: () => import('../../views/store/supplies/Index.vue')
    },
    {
        path: '/supplies/:id',
        name: 'StoreSuppliesUpdate',
        component: () => import('../../views/store/supplies/Update.vue')
    },
    // supplies-plan
    {
        path: '/supplies-plan',
        name: 'StoreSuppliesPlan',
        component: () => import('../../views/store/suppliesPlan/Index.vue')
    },
    {
        path: '/supplies-plan/create',
        name: 'StoreSuppliesPlanCreate',
        component: () => import('../../views/store/suppliesPlan/Create.vue')
    },
    {
        path: '/supplies-plan/:id',
        name: 'StoreSuppliesPlanUpdate',
        component: () => import('../../views/store/suppliesPlan/Update.vue')
    },
    {
        path: '/supplies-plan/:id/purchase',
        name: 'StoreSuppliesPlanPurchase',
        component: () => import('../../views/store/suppliesPlan/Purchase.vue')
    },
    {
        path: '/supplies-plan/:id/confirm',
        name: 'StoreSuppliesPlanConfirm',
        component: () => import('../../views/store/suppliesPlan/Confirm.vue')
    },
    // write-offs
    {
        path: '/write-offs',
        name: 'StoreWriteOffs',
        component: () => import('../../views/store/writeOffs/Index.vue')
    },
    {
        path: '/write-offs/create',
        name: 'StoreWriteOffsCreate',
        component: () => import('../../views/store/writeOffs/Create.vue')
    },
    {
        path: '/write-offs/:id',
        name: 'StoreWriteOffsUpdate',
        component: () => import('../../views/store/writeOffs/Update.vue')
    },
]