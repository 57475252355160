import axios from 'axios'
import qs from 'qs';

export default {
    // admins
    admins(payload) {
        return axios.get('/admins', {params: payload})
    },
    adminsGet(payload) {
        return axios.get('/admins/' + payload.id, payload)
    },
    adminsUpdate(payload) {
        return axios.post('/admins/' + payload.get("id"), payload)
    },
    adminsCreate(payload) {
        return axios.post('/admins', payload)
    },
}
