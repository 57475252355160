import * as types from '@/store/mutation-types'
import router from '@/router'
import api from '@/services/api/metro'
import { buildSuccess, handleError } from '@/utils/utils'

const getters = {
    metroCategories: (state) => state.metroCategories,
    metroProducts: (state) => state.metroProducts,
    metroCategory: (state) => state.metroCategory,
};

const actions = {
    metroCategories({ commit }, payload) {
        return new Promise((resolve, reject) => {
            commit(types.SHOW_LOADING, true);
            api
                .categories(payload)
                .then((response) => {
                    if (response.status === 200) {
                        buildSuccess(
                            null,
                            commit,
                            resolve
                        );
                        commit(types.FILL_METRO_CATEGORIES, response.data);
                    }
                })
                .catch((error) => {
                    handleError(error, commit, reject)
                })
        })
    },
    metroCategoriesProducts({ commit }, payload) {
        return new Promise((resolve, reject) => {
            commit(types.SHOW_LOADING, true);
            api
                .categoriesProducts(payload)
                .then((response) => {
                    if (response.status === 200) {
                        buildSuccess(
                            null,
                            commit,
                            resolve
                        );
                        commit(types.FILL_METRO_PRODUCTS, response.data);
                    }
                })
                .catch((error) => {
                    handleError(error, commit, reject)
                })
        })
    },
    metroCategoriesSettings({ commit }, payload) {
        return new Promise((resolve, reject) => {
            commit(types.SHOW_LOADING, true);
            api
                .categoriesSettings(payload)
                .then((response) => {
                    if (response.status === 200) {
                        buildSuccess(
                            null,
                            commit,
                            resolve,
                            router.back()
                        );
                    }
                })
                .catch((error) => {
                    handleError(error, commit, reject)
                })
        })
    }
};

const mutations = {
    [types.FILL_METRO_CATEGORIES](state, data) {
        state.metroCategories = data;
    },
    [types.FILL_METRO_PRODUCTS](state, data) {
        state.metroCategory = data.category;
        state.metroProducts = data.products;
    }
};

const state = {
    metroCategories: [],
    metroProducts: [],
    metroCategory: null
};

export default {
    state,
    getters,
    actions,
    mutations
}
