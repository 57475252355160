import * as types from '@/store/mutation-types'
import router from '@/router'
import api from '@/services/api/catalog'
import { buildSuccess, handleError } from '@/utils/utils'

const getters = {
    categories: (state) => state.categories,
    categoriesTree: (state) => state.categoriesTree,
    category: (state) => state.category,
    products: (state) => state.products,
};

const actions = {
    // categories
    catalogCategories({ commit }, payload) {
        commit(types.FILL_CATALOG_CATEGORIES, null);
        return new Promise((resolve, reject) => {
            commit(types.SHOW_LOADING, true);
            api
                .categories(payload)
                .then((response) => {
                    if (response.status === 200) {
                        commit(types.FILL_CATALOG_CATEGORIES, response.data);
                        buildSuccess(
                            null,
                            commit,
                            resolve
                        )
                    }
                })
                .catch((error) => {
                    handleError(error, commit, reject)
                })
        })
    },
    catalogCategoriesTree({ commit }, payload) {
        return new Promise((resolve, reject) => {
            commit(types.SHOW_LOADING, true);
            api
                .categoriesTree(payload)
                .then((response) => {
                    if (response.status === 200) {
                        commit(types.FILL_CATALOG_CATEGORIES_TREE, response.data);
                        buildSuccess(
                            null,
                            commit,
                            resolve
                        )
                    }
                })
                .catch((error) => {
                    handleError(error, commit, reject)
                })
        })
    },
    catalogCategoriesSort({ commit }, payload) {
        return new Promise((resolve, reject) => {
            commit(types.SHOW_LOADING, true);
            api
                .categoriesSort(payload)
                .then((response) => {
                    if (response.status === 200) {
                        buildSuccess(
                            null,
                            commit,
                            resolve
                        );
                    }
                })
                .catch((error) => {
                    handleError(error, commit, reject)
                })
        })
    },
    catalogCategoriesProducts({ commit }, payload) {
        commit(types.FILL_CATALOG_PRODUCTS, null);
        return new Promise((resolve, reject) => {
            commit(types.SHOW_LOADING, true);
            api
                .categoriesProducts(payload)
                .then((response) => {
                    if (response.status === 200) {
                        commit(types.FILL_CATALOG_PRODUCTS, response.data);
                        buildSuccess(
                            null,
                            commit,
                            resolve
                        )
                    }
                })
                .catch((error) => {
                    handleError(error, commit, reject)
                })
        })
    },
    catalogCategoriesProductsSort({ commit }, payload) {
        return new Promise((resolve, reject) => {
            commit(types.SHOW_LOADING, true);
            api
                .categoriesProductsSort(payload)
                .then((response) => {
                    if (response.status === 200) {
                        buildSuccess(
                            null,
                            commit,
                            resolve
                        );
                    }
                })
                .catch((error) => {
                    handleError(error, commit, reject)
                })
        })
    },
    // category
    catalogCategoryDelete({ commit }, payload) {
        return new Promise((resolve, reject) => {
            commit(types.SHOW_LOADING, true);
            api
                .categoryDelete(payload)
                .then((response) => {
                    if (response.status === 200) {
                        buildSuccess(
                            null,
                            commit,
                            resolve
                        );
                    }
                })
                .catch((error) => {
                    handleError(error, commit, reject)
                })
        })
    },
    catalogCategoryGet({ commit }, payload) {
        return new Promise((resolve, reject) => {
            commit(types.SHOW_LOADING, true);
            api
                .categoryGet(payload)
                .then((response) => {
                    if (response.status === 200) {
                        commit(types.FILL_CATALOG_CATEGORY, response.data);
                        buildSuccess(
                            null,
                            commit,
                            resolve
                        );
                    }
                })
                .catch((error) => {
                    handleError(error, commit, reject)
                })
        })
    },
    catalogCategoryUpdate({ commit }, payload) {
        return new Promise((resolve, reject) => {
            commit(types.SHOW_LOADING, true);
            api
                .categoryUpdate(payload)
                .then((response) => {
                    if (response.status === 200) {
                        buildSuccess(
                            null,
                            commit,
                            resolve,
                            router.push({
                                name: payload.parent_id === null ? 'CatalogCategories' : 'CatalogSubcategories',
                                params: {
                                    id: payload.parent_id
                                }
                            })
                        );
                    }
                })
                .catch((error) => {
                    handleError(error, commit, reject)
                })
        })
    },
    catalogCategoryCreate({ commit }, payload) {
        return new Promise((resolve, reject) => {
            commit(types.SHOW_LOADING, true);
            api
                .categoryCreate(payload)
                .then((response) => {
                    if (response.status === 200) {
                        buildSuccess(
                            null,
                            commit,
                            resolve,
                            router.push({
                                name: payload.parent_id === 0 ? 'CatalogCategories' : 'CatalogSubcategories',
                                params: {
                                    id: payload.parent_id === 0 ? null : payload.parent_id
                                }
                            })
                        );
                    }
                })
                .catch((error) => {
                    handleError(error, commit, reject)
                })
        })
    },
};

const mutations = {
    [types.FILL_CATALOG_CATEGORIES](state, data) {
        state.categories = data
    },
    [types.FILL_CATALOG_CATEGORY](state, data) {
        state.category = data
    },
    [types.FILL_CATALOG_CATEGORIES_TREE](state, data) {
        state.categoriesTree = data
    },
    [types.FILL_CATALOG_PRODUCTS](state, data) {
        state.products = data
    },
};

const state = {
    categories: [],
    category: null,
    categoriesTree: [],
    products: [],
};

export default {
    state,
    getters,
    actions,
    mutations
}
