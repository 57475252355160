export default [
    {
        path: '/metro',
        name: 'MetroCategories',
        component: () => import('../../views/metro/Index.vue')
    },
    {
        path: '/metro/:id',
        name: 'MetroSubcategories',
        component: () => import('../../views/metro/Index.vue')
    },
    {
        path: '/metro/:id/products',
        name: 'MetroCategoriesProducts',
        component: () => import('../../views/metro/Products.vue')
    },
    {
        path: '/metro/:id/settings',
        name: 'MetroCategoriesSettings',
        component: () => import('../../views/metro/Settings.vue')
    }
]