import * as types from '@/store/mutation-types'
import api from '@/services/api/store'
import router from '@/router'
import { buildSuccess, handleError } from '@/utils/utils'

const getters = {
    suppliesPlan: (state) => state.suppliesPlan,
    supplyPlan: (state) => state.supplyPlan,
};

const actions = {
    storeSuppliesPlan({ commit }, payload) {
        return new Promise((resolve, reject) => {
            commit(types.SHOW_LOADING, true);
            commit(types.FILL_STORE_SUPPLIES_PLAN, []);
            api
                .suppliesPlan(payload)
                .then((response) => {
                    if (response.status === 200) {
                        buildSuccess(
                            null,
                            commit,
                            resolve
                        );
                        commit(types.FILL_STORE_SUPPLIES_PLAN, response.data);
                    }
                })
                .catch((error) => {
                    handleError(error, commit, reject)
                })
        })
    },
    storeSuppliesPlanDelete({ commit }, payload) {
        return new Promise((resolve, reject) => {
            commit(types.SHOW_LOADING, true);
            api
                .suppliesPlanDelete(payload)
                .then((response) => {
                    if (response.status === 200) {
                        buildSuccess(
                            null,
                            commit,
                            resolve
                        );
                    }
                })
                .catch((error) => {
                    handleError(error, commit, reject)
                })
        })
    },
    storeSuppliesPlanGet({ commit }, payload) {
        return new Promise((resolve, reject) => {
            commit(types.SHOW_LOADING, true);
            api
                .suppliesPlanGet(payload)
                .then((response) => {
                    if (response.status === 200) {
                        buildSuccess(
                            null,
                            commit,
                            resolve
                        );
                        commit(types.FILL_STORE_SUPPLY_PLAN, response.data);
                    }
                })
                .catch((error) => {
                    handleError(error, commit, reject)
                })
        })
    },
    storeSuppliesPlanUpdate({ commit }, payload) {
        return new Promise((resolve, reject) => {
            commit(types.SHOW_LOADING, true);
            api
                .suppliesPlanUpdate(payload)
                .then((response) => {
                    if (response.status === 200) {
                        buildSuccess(
                            null,
                            commit,
                            resolve,
                            router.go()
                        );
                    }
                })
                .catch((error) => {
                    handleError(error, commit, reject)
                })
        })
    },
    storeSuppliesPlanCreate({ commit }, payload) {
        return new Promise((resolve, reject) => {
            commit(types.SHOW_LOADING, true);
            api
                .suppliesPlanCreate(payload)
                .then((response) => {
                    if (response.status === 200) {
                        buildSuccess(
                            null,
                            commit,
                            resolve,
                            router.push({
                                name: 'StoreSuppliesPlan'
                            })
                        );
                    }
                })
                .catch((error) => {
                    handleError(error, commit, reject)
                })
        })
    },
    storeSuppliesPlanPurchase({ commit }, payload) {
        return new Promise((resolve, reject) => {
            commit(types.SHOW_LOADING, true);
            api
                .suppliesPlanPurchase(payload)
                .then((response) => {
                    if (response.status === 200) {
                        buildSuccess(
                            null,
                            commit,
                            resolve,
                            router.push({
                                name: 'StoreSuppliesPlan'
                            })
                        );
                    }
                })
                .catch((error) => {
                    handleError(error, commit, reject)
                })
        })
    },
    storeSuppliesPlanConfirm({ commit }, payload) {
        return new Promise((resolve, reject) => {
            commit(types.SHOW_LOADING, true);
            api
                .suppliesPlanConfirm(payload)
                .then((response) => {
                    if (response.status === 200) {
                        buildSuccess(
                            null,
                            commit,
                            resolve,
                            router.push({
                                name: 'StoreSupplies'
                            })
                        );
                    }
                })
                .catch((error) => {
                    handleError(error, commit, reject)
                })
        })
    },
};

const mutations = {
    [types.FILL_STORE_SUPPLIES_PLAN](state, data) {
        state.suppliesPlan = data
    },
    [types.FILL_STORE_SUPPLY_PLAN](state, data) {
        state.supplyPlan = data
    }
};

const state = {
    suppliesPlan: [],
    supplyPlan: null,
};

export default {
    state,
    getters,
    actions,
    mutations
}
