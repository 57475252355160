import * as types from '@/store/mutation-types'
import api from '@/services/api/catalog'
import { buildSuccess, handleError } from '@/utils/utils'

const getters = {
    weeklyDeals: (state) => state.weeklyDeals
};

const actions = {
    catalogWeeklyDeals({ commit }, payload) {
        return new Promise((resolve, reject) => {
            commit(types.SHOW_LOADING, true);
            api
                .weeklyDeals(payload)
                .then((response) => {
                    if (response.status === 200) {
                        buildSuccess(
                            null,
                            commit,
                            resolve
                        );
                        commit(types.FILL_CATALOG_WEEKLYDEALS, response.data);
                    }
                })
                .catch((error) => {
                    handleError(error, commit, reject)
                })
        })
    }
};

const mutations = {
    [types.FILL_CATALOG_WEEKLYDEALS](state, data) {
        state.weeklyDeals = data
    }
};

const state = {
    weeklyDeals: []
};

export default {
    state,
    getters,
    actions,
    mutations
}
