import * as types from '@/store/mutation-types'
import api from '@/services/api/store'
import router from '@/router'
import { buildSuccess, handleError } from '@/utils/utils'

const getters = {
    writeOffs: (state) => state.writeOffs,
    writeOff: (state) => state.writeOff,
};

const actions = {
    storeWriteOffs({ commit }, payload) {
        return new Promise((resolve, reject) => {
            commit(types.SHOW_LOADING, true);
            api
                .writeOffs(payload)
                .then((response) => {
                    if (response.status === 200) {
                        buildSuccess(
                            null,
                            commit,
                            resolve
                        );
                        commit(types.FILL_STORE_WRITE_OFFS, response.data);
                    }
                })
                .catch((error) => {
                    handleError(error, commit, reject)
                })
        })
    },
    storeWriteOffsGet({ commit }, payload) {
        return new Promise((resolve, reject) => {
            commit(types.SHOW_LOADING, true);
            api
                .writeOffsGet(payload)
                .then((response) => {
                    if (response.status === 200) {
                        buildSuccess(
                            null,
                            commit,
                            resolve
                        );
                        commit(types.FILL_STORE_WRITE_OFF, response.data);
                    }
                })
                .catch((error) => {
                    handleError(error, commit, reject)
                })
        })
    },
    storeWriteOffsUpdate({ commit }, payload) {
        return new Promise((resolve, reject) => {
            commit(types.SHOW_LOADING, true);
            api
                .writeOffsUpdate(payload)
                .then((response) => {
                    if (response.status === 200) {
                        buildSuccess(
                            null,
                            commit,
                            resolve,
                            router.go()
                        );
                    }
                })
                .catch((error) => {
                    handleError(error, commit, reject)
                })
        })
    },
    storeWriteOffsCreate({ commit }, payload) {
        return new Promise((resolve, reject) => {
            commit(types.SHOW_LOADING, true);
            api
                .writeOffsCreate(payload)
                .then((response) => {
                    if (response.status === 200) {
                        buildSuccess(
                            null,
                            commit,
                            resolve,
                            router.push({
                                name: 'StoreWriteOffs'
                            })
                        );
                    }
                })
                .catch((error) => {
                    handleError(error, commit, reject)
                })
        })
    },
};

const mutations = {
    [types.FILL_STORE_WRITE_OFFS](state, data) {
        state.writeOffs = data
    },
    [types.FILL_STORE_WRITE_OFF](state, data) {
        state.writeOff = data
    }
};

const state = {
    writeOffs: [],
    writeOff: null,
};

export default {
    state,
    getters,
    actions,
    mutations
}
