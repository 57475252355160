export default [
    // orders
    {
        path: '/orders',
        name: 'DeliveryOrders',
        component: () => import('../../views/delivery/orders/Index.vue')
    },
    {
        path: '/orders/:id/update',
        name: 'DeliveryOrdersUpdate',
        component: () => import('../../views/delivery/orders/Update.vue')
    },
    {
        path: '/orders/:id/add-product',
        name: 'DeliveryOrdersAddProduct',
        component: () => import('../../views/delivery/orders/AddProduct.vue')
    },
    // users
    {
        path: '/users',
        name: 'DeliveryUsers',
        component: () => import('../../views/delivery/users/Index.vue')
    }
]