import * as types from '@/store/mutation-types'
import router from '@/router'
import api from '@/services/api/delivery'
import { buildSuccess, handleError } from '@/utils/utils'

const getters = {
    users: (state) => state.users,
};

const actions = {
    deliveryUsers({ commit }, payload) {
        return new Promise((resolve, reject) => {
            commit(types.SHOW_LOADING, true);
            api
                .users(payload)
                .then((response) => {
                    if (response.status === 200) {
                        buildSuccess(
                            null,
                            commit,
                            resolve
                        );
                        commit(types.FILL_DELIVERY_USERS, response.data);
                    }
                })
                .catch((error) => {
                    handleError(error, commit, reject)
                })
        })
    }
};

const mutations = {
    [types.FILL_DELIVERY_USERS](state, data) {
        state.users = data
    },
};

const state = {
    users: null,
};

export default {
    state,
    getters,
    actions,
    mutations
}
