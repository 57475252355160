import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { store } from '@/store'
import Axios from "axios";
import '@/plugins/axios'
import VueToastr from "vue-toastr";
import VueMoment from "vue-moment";
import {appConfig} from "./config.js";
import BootstrapVue from 'bootstrap-vue'

import '@/assets/css/app.scss'

Vue.use(BootstrapVue);
Vue.use(VueToastr);
Vue.use(VueMoment);
Vue.use(appConfig);

window.appConfig = appConfig;
Axios.defaults.baseURL = appConfig.baseAPIURL;

Vue.config.productionTip = false;

new Vue({
    router,
    store,
    render: h => h(App),
    created() {
        if (store.getters.isTokenSet) {
            store.dispatch('autoLogin')
        }
    }
}).$mount('#app');
