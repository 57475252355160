import camelCase from 'lodash/camelCase'

import modulesCatalog from '@/store/modules/catalog'
import modulesDelivery from '@/store/modules/delivery'
import modulesStore from '@/store/modules/store'

const modules =  {
    ...modulesCatalog,
    ...modulesDelivery,
    ...modulesStore
};

const requireModule = require.context('.', false, /\.js$/); // Get js files inside modules folder

requireModule.keys().forEach((fileName) => {
    // Avoid the index.js file
    if (fileName === './index.js') {
        return
    }
    const moduleName = camelCase(fileName.replace(/(\.\/|\.js)/g, ''))
    modules[moduleName] = requireModule(fileName).default
})

export default modules
