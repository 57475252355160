<template>
    <div id="app">
        <div v-if="this.$route.name !== 'Login'"
             class="sidebar"
             v-cloak>
            <div v-if="this.checkRole('products', '*') || this.checkRole('manufacturers', '*') || this.checkRole('promotions', '*') || this.checkRole('weekly_deals', '*')"
                 class="sidebar__project">
                <span class="sidebar__project-title"><i class="material-icons">shopping_cart</i> Каталог</span>
                <ul class="sidebar__project-menu">
                    <li v-if="this.checkRole('products', '*')"
                        v-bind:class="{'sidebar__project-menu--active': (['CatalogCategories', 'CatalogSubcategories', 'CatalogCategoriesProducts', 'CatalogCategoryCreate', 'CatalogCategoryUpdate', 'CatalogProductUpdate', 'CatalogProductCreate']).includes(this.$route.name) }">
                        <router-link :to="{ name: 'CatalogCategories', params: { id: null } }">Товары</router-link>
                    </li>
                    <li v-if="this.checkRole('manufacturers', '*')"
                        v-bind:class="{'sidebar__project-menu--active': (['CatalogManufacturers', 'CatalogManufacturersCreate', 'CatalogManufacturersUpdate']).includes(this.$route.name) }">
                        <router-link :to="{ name: 'CatalogManufacturers' }">Производители</router-link>
                    </li>
                    <li v-if="this.checkRole('promotions', '*')"
                        v-bind:class="{'sidebar__project-menu--active': (['CatalogPromotions', 'CatalogPromotionsCreate', 'CatalogPromotionsUpdate']).includes(this.$route.name) }">
                        <router-link :to="{ name: 'CatalogPromotions' }">Акции</router-link>
                    </li>
                    <li v-if="this.checkRole('weekly_deals', '*')"
                        v-bind:class="{'sidebar__project-menu--active': (['CatalogWeeklyDeals']).includes(this.$route.name) }">
                        <router-link :to="{ name: 'CatalogWeeklyDeals' }">Еженедельные предложения</router-link>
                    </li>
                </ul>
            </div>
            <div v-if="this.checkRole('stores', '*') || this.checkRole('supplies_plan', '*') || this.checkRole('supplies', '*') || this.checkRole('write_offs', '*')"
                 class="sidebar__project">
                <span class="sidebar__project-title"><i class="material-icons">store</i> Магазины</span>
                <ul class="sidebar__project-menu">
                    <li v-if="this.checkRole('stores', '*')"
                        v-bind:class="{'sidebar__project-menu--active': (['StoreStores', 'StoreStoresCreate', 'StoreStoresUpdate', 'StoreStoresLeftovers']).includes(this.$route.name) }">
                        <router-link :to="{ name: 'StoreStores' }">Склады</router-link>
                    </li>
                    <li v-if="this.checkRole('supplies_plan', '*')"
                        v-bind:class="{'sidebar__project-menu--active': (['StoreSuppliesPlan', 'StoreSuppliesPlanCreate', 'StoreSuppliesPlanUpdate', 'StoreSuppliesPlanPurchase', 'StoreSuppliesPlanConfirm']).includes(this.$route.name) }">
                        <router-link :to="{ name: 'StoreSuppliesPlan' }">План поставок</router-link>
                    </li>
                    <li v-if="this.checkRole('supplies', '*')"
                        v-bind:class="{'sidebar__project-menu--active': (['StoreSupplies', 'StoreSuppliesUpdate']).includes(this.$route.name) }">
                        <router-link :to="{ name: 'StoreSupplies' }">Поставки</router-link>
                    </li>
                    <li v-if="this.checkRole('write_offs', '*')"
                        v-bind:class="{'sidebar__project-menu--active': (['StoreWriteOffs', 'StoreWriteOffsCreate', 'StoreWriteOffsUpdate']).includes(this.$route.name) }">
                        <router-link :to="{ name: 'StoreWriteOffs' }">Списания</router-link>
                    </li>
                </ul>
            </div>
            <div v-if="this.checkRole('orders', '*') || this.checkRole('users', '*')"
                 class="sidebar__project">
                <span class="sidebar__project-title"><i class="material-icons">room_service</i> Доставка</span>
                <ul class="sidebar__project-menu">
                    <li v-if="this.checkRole('orders', '*')"
                         v-bind:class="{'sidebar__project-menu--active': (['DeliveryOrders', 'DeliveryOrdersUpdate']).includes(this.$route.name) }">
                        <router-link :to="{ name: 'DeliveryOrders' }">Поръчки</router-link>
                    </li>
                    <li v-if="this.checkRole('users', '*')"
                         v-bind:class="{'sidebar__project-menu--active': (['DeliveryUsers', 'DeliveryUsersUpdate']).includes(this.$route.name) }">
                        <router-link :to="{ name: 'DeliveryUsers' }">Потребители</router-link>
                    </li>
                </ul>
            </div>
            <div v-if="this.checkRole('*', '*')"
                 class="sidebar__project">
                <span class="sidebar__project-title"><i class="material-icons">folder</i> Хладилник</span>
                <ul class="sidebar__project-menu">
                    <li v-bind:class="{'sidebar__project-menu--active': (['MetroCategories', 'MetroSubcategories', 'MetroCategoriesProducts', 'MetroCategoriesSettings']).includes(this.$route.name) }">
                        <router-link :to="{ name: 'MetroCategories' }">Metro</router-link>
                    </li>
                    <li v-bind:class="{'sidebar__project-menu--active': (['AdminsIndex', 'AdminsUpdate']).includes(this.$route.name) }">
                        <router-link :to="{ name: 'AdminsIndex' }">Администраторы</router-link>
                    </li>
                </ul>
            </div>
        </div>

        <div class="header">
            <a href="/">
                <div class="header__logo"></div>
            </a>

            <div class="header__buttons">

                <div v-if="this.$store.state.loading.showLoading" class="header__loader spinner-border text-primary" role="status">
                    <span class="sr-only">Loading...</span>
                </div>

                <input
                        v-if="this.$store.state.auth.isTokenSet"
                        @click="logout()"
                        type="button"
                        class="btn btn-danger"
                        value="Выйти">
            </div>
        </div>

        <div v-bind:class="{'content--padding-left': this.$route.name !== 'Login'}" class="content">
            <div class="container">
                <div v-if="this.$store.state.error.showErrorMessage" class="alert alert-danger mt-3" role="alert">
                    {{ this.$store.state.error.errorMessage }}
                </div>
                <router-view/>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapActions } from 'vuex'

    export default {
        name: 'App',
        methods: {
            ...mapActions(['authLogout']),
            async logout() {
                await this.authLogout()
            }, // this.$parent.$parent.isAddressModalOpen
            checkRole(controller, action) {
                let isAvailable = false;

                Object.keys(this.role).forEach(roleController => {
                    if (roleController === '*') {
                        isAvailable = true;
                    } else if (roleController === controller) {
                        for (let i=0; i<this.role[roleController].length; i++) {
                            if (this.role[roleController][i] === '*') {
                                isAvailable = true;
                                break;
                            } else if (this.role[roleController][i] === action) {
                                isAvailable = true;
                                break;
                            }
                        }
                    }
                });

                return isAvailable
            }
        },
        computed: {
            role() {
                return this.$store.state.auth.role
            },
            admin() {
                return this.$store.state.auth.admin
            }
        }
    }
</script>

<style lang="scss">
    @import url('https://fonts.googleapis.com/css2?family=Material+Icons&display=swap');
    @import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,300;0,400;0,700;1,400&display=swap');

    .sidebar {
        position: fixed;
        top: 0;
        left: 0;
        width: 290px;
        height: 100%;
        background: #272c33;
        -webkit-box-shadow: 1px 0 20px rgba(0, 0, 0, 0.08);
        box-shadow: 1px 0 20px rgba(0, 0, 0, 0.08);
        padding-top: 70px;
        overflow-y: auto;

        &__project {
            margin-top: 20px;

            &-title {
                color: #798699;
                height: 40px;
                display: block;
                line-height: 40px;
                border-left: 3px solid #272c33;
                position: relative;
                padding-left: 55px;
                font-size: 18px;
                cursor: default;

                &--active {
                    color: #009efb;
                    border-left: 3px solid #009efb;
                }

                i {
                    position: absolute;
                    top: 8px;
                    left: 15px;
                }
            }

            &-menu {
                margin: 5px 0 20px 42px;

                a {
                    display: block;
                    color: #677384;
                    padding: 5px 35px 5px 17px;
                    line-height: 20px;

                    &:hover, &:focus {
                        color: #009efb;
                    }
                }

                &--active {
                    a {
                        color: #fff;

                        &:hover, &:focus {
                            color: #fff;
                        }
                    }
                }
            }
        }
    }
</style>
