import * as types from '@/store/mutation-types'
import { store } from '@/store'

// Catches error connection or any other error (checks if error.response exists)
export const handleError = (error, commit, reject) => {
    let errMsg = ''
    // Resets errors in store
    commit(types.SHOW_LOADING, false)
    commit(types.ERROR, null)

    // Checks if unauthorized
    if (error && error.response && error.response.status === 401) {
        store.dispatch('authLogout')
    } else {
        errMsg = error && error.response && error.response.data
            ? error.response.data.message
            : 'SERVER_TIMEOUT_CONNECTION_ERROR';
        setTimeout(() => {
            return errMsg
                ? commit(types.ERROR, errMsg)
                : commit(types.SHOW_ERROR, false)
        }, 0)
    }
    reject(error)
}

export const buildSuccess = (
    msg,
    commit,
    resolve,
    resolveParam = undefined
) => {
    commit(types.SHOW_LOADING, false)
    // commit(types.SUCCESS, null)
    // setTimeout(() => {
    //     return msg ? commit(types.SUCCESS, msg) : commit(types.SHOW_SUCCESS, false)
    // }, 0)
    commit(types.ERROR, null)
    resolve(resolveParam)
}