import axios from 'axios'
import qs from 'qs';

export default {
    // categories
    categories(payload) {
        return axios.get('/categories' + (payload.id !== null ? "/" + payload.id : ""), payload)
    },
    categoriesSort(payload) {
        return axios.post('/categories/sort', qs.stringify(payload))
    },
    categoriesTree(payload) {
        return axios.get('/categories/tree', payload)
    },
    categoriesProducts(payload) {
        return axios.get('/categories/' + payload.id + '/products', payload)
    },
    categoriesProductsSort(payload) {
        return axios.post('/categories/' + payload.id + '/products/sort', qs.stringify(payload))
    },
    // category
    categoryGet(payload) {
        return axios.get('/category/' + payload.id)
    },
    categoryDelete(payload) {
        return axios.delete('/category/' + payload.id)
    },
    categoryUpdate(payload) {
        return axios.post('/category/' + payload.get("id"), payload)
    },
    categoryCreate(payload) {
        return axios.post('/category', payload)
    },
    // products
    productCreate(payload) {
        return axios.post('/products', payload)
    },
    productGet(payload) {
        return axios.get('/products/' + payload.id, payload)
    },
    productUpdate(payload) {
        return axios.post('/products/' + payload.get("id"), payload)
    },
    productDelete(payload) {
        return axios.post('/products/' + payload.id)
    },
    // manufacturers
    manufacturers(payload) {
        return axios.get('/manufacturers', {params: payload})
    },
    manufacturersDelete(payload) {
        return axios.delete('/manufacturers/' + payload.id, payload)
    },
    manufacturersUpdate(payload) {
        return axios.post('/manufacturers/' + payload.get("id"), payload)
    },
    manufacturersCreate(payload) {
        return axios.post('/manufacturers', payload)
    },
    manufacturersGet(payload) {
        return axios.get('/manufacturers/' + payload.id, payload)
    },
    // promotions
    promotions(payload) {
        return axios.get('/promotions', payload)
    },
    promotionsDelete(payload) {
        return axios.delete('/promotions/' + payload.id, payload)
    },
    promotionsUpdate(payload) {
        return axios.post('/promotions/' + payload.get("id"), payload)
    },
    promotionsCreate(payload) {
        return axios.post('/promotions', payload)
    },
    promotionsGet(payload) {
        return axios.get('/promotions/' + payload.id, payload)
    },
    promotionsProducts(payload) {
        return axios.get('/promotions/products', payload)
    },
    promotionsSort(payload) {
        return axios.post('/promotions/sort', qs.stringify(payload))
    },
    // weeklyDeals
    weeklyDeals(payload) {
        return axios.get('/weekly-deals', payload)
    },
}
