import axios from 'axios'
import qs from 'qs';

export default {
    // orders
    orders(payload) {
        return axios.get('/orders', {params: payload})
    },
    ordersGet(payload) {
        return axios.get('/orders/' + payload.id, payload)
    },
    ordersAddress(payload) {
        return axios.post('/orders/' + payload.id + '/address', qs.stringify(payload))
    },
    ordersProducts(payload) {
        return axios.post('/orders/' + payload.id + '/products', qs.stringify(payload))
    },
    ordersStatus(payload) {
        return axios.post('/orders/' + payload.id + '/status', qs.stringify(payload))
    },
    ordersStatusesHistory(payload) {
        return axios.get('/orders/' + payload.id + '/statusesHistory', payload)
    },
    ordersProductsHistory(payload) {
        return axios.get('/orders/' + payload.id + '/productsHistory', payload)
    },
    ordersPaymentMethod(payload) {
        return axios.post('/orders/' + payload.id + '/paymentMethod', qs.stringify(payload))
    },
    // users
    users(payload) {
        return axios.get('/users', {params: payload})
    },
}
