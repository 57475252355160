export default [
    // admins
    {
        path: '/admins',
        name: 'AdminsIndex',
        component: () => import('../../views/admins/Index.vue')
    },
    {
        path: '/admins/:id/update',
        name: 'AdminsUpdate',
        component: () => import('../../views/admins/Update.vue')
    },
]