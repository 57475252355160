export default [
    // categories
    {
        path: '/categories',
        name: 'CatalogCategories',
        component: () => import('../../views/catalog/categories/Index.vue')
    },
    {
        path: '/categories/:id',
        name: 'CatalogSubcategories',
        component: () => import('../../views/catalog/categories/Index.vue')
    },
    {
        path: '/categories/:id/products',
        name: 'CatalogCategoriesProducts',
        component: () => import('../../views/catalog/categories/Products.vue')
    },
    {
        path: '/category/create',
        name: 'CatalogCategoryCreate',
        component: () => import('../../views/catalog/categories/Create.vue')
    },
    {
        path: '/category/:id/update',
        name: 'CatalogCategoryUpdate',
        component: () => import('../../views/catalog/categories/Update.vue')
    },
    // products
    {
        path: '/product/:id/update',
        name: 'CatalogProductUpdate',
        component: () => import('../../views/catalog/product/Update.vue')
    },
    {
        path: '/product/create',
        name: 'CatalogProductCreate',
        component: () => import('../../views/catalog/product/Create.vue')
    },
    // manufacturers
    {
        path: '/manufacturers',
        name: 'CatalogManufacturers',
        component: () => import('../../views/catalog/manufacturers/Index.vue')
    },
    {
        path: '/manufacturers/create',
        name: 'CatalogManufacturersCreate',
        component: () => import('../../views/catalog/manufacturers/Create.vue')
    },
    {
        path: '/manufacturers/:id',
        name: 'CatalogManufacturersUpdate',
        component: () => import('../../views/catalog/manufacturers/Update.vue')
    },
    // promotions
    {
        path: '/promotions',
        name: 'CatalogPromotions',
        component: () => import('../../views/catalog/promotions/Index.vue')
    },
    {
        path: '/promotions/create',
        name: 'CatalogPromotionsCreate',
        component: () => import('../../views/catalog/promotions/Create.vue')
    },
    {
        path: '/promotions/:id',
        name: 'CatalogPromotionsUpdate',
        component: () => import('../../views/catalog/promotions/Update.vue')
    },
    // weeklyDeals
    {
        path: '/weekly-deals',
        name: 'CatalogWeeklyDeals',
        component: () => import('../../views/catalog/weeklyDeals/Index.vue')
    },
]